<!--
 * @Author: yangliao
 * @Date: 2020-11-27 16:36:52
 * @LastEditTime: 2020-12-17 14:30:44
 * @LastEditors: Please set LastEditors
 * @Description:阶梯人口须知
 * @FilePath: /netHallOfficialAccounts/src/views/Business/Population/index.vue
-->

<template>
  <div class="notice">
    <div class="notice-card">
      <van-cell is-link center @click="cardShow = true">
        <template #title>
          <div v-if="!customerInfor.accountNo" class="custom-title">
            请绑定您要办理业务的用水账户
          </div>
          <div v-if="customerInfor.accountNo">
            <van-field class="notice-card-cell" label="户号" labelWidth="40">
              <template #input>
                {{ customerInfor.accountNo }}
              </template>
            </van-field>
            <van-field class="notice-card-cell" label="地址" labelWidth="40">
              <template #input>
                {{ customerInfor.accountAddress }}
              </template>
            </van-field>
          </div>
        </template>
      </van-cell>

      <van-field class="block-field btn" label="变更阶梯人口申请表下载" name="businessType" readonly>
          <template #input>
            <div class="download">
              <van-button plain type="info" style="height: 40px;" native-type="button" @click="download('stepFavor', '变更阶梯人口申请表')">
                变更阶梯人口申请表
              </van-button>
            </div>
          </template>
      </van-field>
    </div>
    <div class="notice-content">
      <div v-html="noticeHtml"></div>
    </div>
    <div class="notice-foot">
      <div class="notice-foot-left">
        <van-checkbox v-model="checked" shape="square" icon-size="20px">已阅读</van-checkbox>
      </div>
      <div class="notice-foot-right">
        <van-button class="nfr-button" :disabled="!checked" type="info" @click="verification">
          下一步
        </van-button>
      </div>
    </div>
    <van-popup class="card-model" v-model="cardShow" :style="{ width: '100%', height: '100%' }">
      <div class="card-model-group">
        <div class="card-model-group-title">
          <span>已绑定用水账户</span>
        </div>
        <div class="card-model-group-news">
          <van-radio-group v-model="cardRadio">
            <van-radio
              v-for="(item, index) in cardAccount"
              :key="index"
              class="card-model-group-radio"
              :name="item.wuserNo"
              @click="selectCardRadio(item)"
            >
              <div class="cmgr-name"><span>户号：</span>{{ item.wuserNo }}</div>
              <div class="cmgr-name"><span>地址：</span>{{ item.wuserAddress }}</div>
            </van-radio>
          </van-radio-group>
        </div>
        <div class="submit-button">
          <van-button type="info" @click="jumpBind">绑定新用水账户</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from "vuex";
import { Toast } from 'vant';
import { getAllDict } from '@/api/components';
import { termsReckon } from '@/utils/businessRoute';
import { getCustReadingByCode, selectAccount, verification } from '@/api/business';
export default {
  data() {
    return {
      customersNews: null,
      customerInfor: {
        accountNo: null,
        accountAddress: null
      },
      cardAccount: null,
      cardShow: false,
      cardRadio: '',
      businessCode: '0004',
      moduleCode: '0004',
      moduleName: '',
      checked: false,
      custReadingData: null,
      noticeHtml: null,
      code: null
    };
  },
  computed: {
    ...mapState("user", {
      homeInfo: (state) => state.homeInfo,
      pathUrl: (state) => state.pathUrl
    }),
    businessTerms() {
      return termsReckon(this.businessCode);
    }
  },
  mounted() {
    this.into();
  },
  methods: {
    download(name, file) {
      let filename = name;
      let a = document.createElement("a");
      let realFilename = encodeURIComponent(filename);
      a.href = realFilename + ".pdf";
      //路径中'/'为根目录，即index.html所在的目录
      a.download = file + ".pdf";
      a.click();
    },
    async into() {
      this.getReadingByCode();
      this.selectAccount();
    },
    /* 绑定表卡选择 */
    selectCardRadio(data) {
      this.customersNews = data;
      this.customerInfor.accountNo = data.wuserNo;
      this.customerInfor.accountAddress = data.wuserAddress;
      this.cardShow = false;
    },
    /* 新用户绑定挑转 */
    async jumpBind() {
      this.code = '0004' // code: 00 -> 约定成业务里面的
      let data = {
        url: '/Business/PopulationNotice',
        code: this.code 
      }
      await store.dispatch('user/savePathUrl', data)
      this.$router.push({ path: '/Bind' });
    },
    /* 下一步 */
    async nextStep() {
      if (this.customerInfor.accountNo) {
        let data = {
          businessState: 'add',
          businessCode: this.businessCode,
          moduleCode: this.moduleCode,
          busHandlingId: null,
          custReadingId: this.custReadingData.custReadingId,
          moduleName: this.businessTerms.name,
          customersNews: this.customersNews
        };
        let item = { ...data, ...this.customerInfor };
        console.log(item);
        await store.dispatch('user/setBusinessNews', item);
        this.$router.push({ path: '/Business/Population' });
      } else {
        Toast.fail('请选择用水账户');
      }
    },
    /* 获取用户须知 */
    async getReadingByCode() {
      let data = { businessCode: this.businessCode, moduleCode: this.moduleCode };
      let { status, resultData } = await getCustReadingByCode(data);
      if (status === 'complete' && resultData) {
        this.custReadingData = resultData;
        this.noticeHtml = resultData.content;
      } else {
        this.custReadingData = null;
        this.noticeHtml = null;
      }
    },
    /* 获取绑定表卡 */
    async selectAccount() {
      let { status, resultData } = await selectAccount();
      if (status === 'complete' && resultData) {
        this.cardAccount = resultData;
        if (resultData.length === 1) {
          this.cardRadio = resultData[0].wuserNo;
          this.customerInfor.accountNo = resultData[0].wuserNo;
          this.customerInfor.accountAddress = resultData[0].wuserAddress;
        }
        // 如果是选择绑定新的户号：
        if (this.pathUrl && this.pathUrl.code === '0004') {
          this.cardRadio = resultData[0].wuserNo;
          this.customerInfor.accountNo = resultData[0].wuserNo;
          this.customerInfor.accountAddress = resultData[0].wuserAddress;
        }
      }
    },
    // 校验后进入下一步
    async verification() {
      if (this.customerInfor.accountNo) {
        let arg = {
          wuserNo: this.customerInfor.accountNo,
          moduleCode: this.moduleCode
        }
        verification(arg).then(res => {
          const {status, resultData} = res;
          if (status === 'complete') {
            this.nextStep();
          }
        }).catch((e) => {
          if (e.errorCode === "bus_02") {
            this.$router.push({
                name: 'PaymentPage',
                path: '/QueryPay/PaymentPage',
                query: {
                  wuserNo: this.customerInfor.accountNo,
                  state: 'business'
                }
            })
          }
        })
      } else {
        Toast('请绑定您要办理业务的用水账户')
      }
    }
  },
  destroyed() {
    // 出去清除状态：
    this.businessTerms.child.map((item) => {
      if (item.disabled) {
        delete(item.disabled)
      }
    })
    // 出去清除路由里面的跳转状态
    if (this.pathUrl && !this.code) {
      this.pathUrl.code = '';
      this.pathUrl.url = '';
    }
  },

};
</script>

<style lang="less" scoped>
.notice {
  display: flex;
  flex-direction: column;
  .notice-card {
    background: #ffffff;
    margin-top: 16px;
    .van-cell::after {
      border: none;
    }
    /deep/.van-cell {
      background-color: transparent;
    }
    .custom-title {
      padding: 6px 0;
    }
    .notice-card-cell {
      padding: 6px 0;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .notice-content {
    background: #ffffff;
    margin: 16px 0 80px 0;
    padding: 0 16px;
    overflow: hidden;
    /deep/ ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    /deep/ ul ul {
      list-style-type: circle;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
    /deep/ul ul ul {
      list-style-type: square;
    }
    /deep/ol {
      list-style: decimal inside;
      padding-left: 40px;
      margin: 16px 0;
    }
  }
  .notice-foot {
    width: 100%;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-top: 1px solid #ebedf0;
    .notice-foot-left {
      margin-left: 16px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .notice-foot-right {
      margin-right: 16px;
      .nfr-button {
        width: 110px;
        height: 40px;
        background: #1d6fe9;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  .card-model {
    background-color: #f6f6f6;

    .card-model-group {
      .card-model-group-title {
        margin: 10px 16px;
        font-family: PingFangSC-Medium;
        color: #1d6fe9;
        font-size: 14px;
      }

      .card-model-group-news {
        margin-bottom: 68px;
        .card-model-group-radio {
          margin: 16px;
          padding: 10px 16px;
          border-radius: 6px;
          overflow: hidden;
          background-color: #ffffff;

          .cmgr-name {
            margin: 10px 6px;
          }
        }
      }

      .submit-button {
        // position: fixed;
        width: 100%;
        height: 100%;
        bottom: 0px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        .van-button__text {
          font-family: PingFangSC-Regular;
          font-size: 18px;
          color: #ffffff;
          text-align: center;
        }
        .van-button {
          border-radius: 8px;
          width: 90%;
        }
      }
    }
  }
}
</style>
